import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const CurrencySorter = ({ data }) => {
  return (
    <Layout>
      <SEO title="JetScan iFX i400 Multi-Pocket Currency Sorter" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              JetScan iFX i400 Multi-Pocket Currency Sorter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Smallest, fastest, most flexible multi-pocket currency scanner and
              sorter
            </h3>
            <p className="mb-4">
              Significantly smaller, 39% faster and more flexible than any other
              multi-pocket currency sorter and processor, the JetScan iFX® i400
              can have as few as 3 to as many as 17 pockets.
            </p>
            <h3 className="mb-6 text-3xl font-bold leading-none">Features</h3>
            <ul className="mb-4 ml-4 list-disc">
              <li>
                <strong>High speed currency counting and processing:</strong>{' '}
                Process currency at up to 1,000 notes per minute with less
                stopping for greater throughput, making money counting and
                sorting easier.
              </li>
              <li>
                <strong>Configurable sorting pockets:</strong> Easily configure
                pockets for any denomination, and all pockets can be fully
                utilized for sorting or strap preparation. LED indicators
                provide status information for each pocket. Quickly set up and
                modify sort modes.
              </li>
              <li>
                <strong>Assignable off-sort pockets:</strong> Designate one or
                two off-sort pockets to collect rejected or suspect documents.
              </li>
              <li>
                <strong>Flexible system configuration:</strong> Configure from a
                3 to 17-pocket system in a vertical or horizontal orientation to
                accommodate your space or operating requirements.
              </li>
              <li>
                <strong>Counterfeit Detection:</strong> The most advanced
                counterfeit money detection in the industry. Patented sensors
                and analytic software analyze each bill, catching counterfeits
                that others miss.
              </li>
              <li>
                <strong>Dynamic sorting:</strong> Reassigns empty pockets to a
                denomination on-the-fly and as needed, resulting in fewer
                switches of process modes and fewer stops from pocket-full
                conditions.
              </li>
              <li>
                <strong>Intelligent jam clearing:</strong> Guides a user through
                the quick and easy process of clearing the jam, reconciling the
                count with minimal steps and resuming operation.
              </li>
              <li>
                <strong>Continuous feed hopper:</strong> Automatic continuous
                feeding with 800-note capacity.
              </li>
              <li>
                <strong>Double count:</strong> Counting accuracy is verified by
                a second count as bills pass through the transport path.
              </li>
              <li>
                <strong>Easy-to-use:</strong> Change processing modes with the
                touch of a button on an 8" color touch-screen display. Option to
                show only the buttons needed for daily operation.
              </li>
              <li>
                <strong>Future-ready design:</strong> Easily upgrade select
                models with a software activation key as new features become
                available.
              </li>
              <li>
                <strong>Stranger mode:</strong> Reject all other denominations
                other than single target denomination. Reduces number of finite
                sort modes.
              </li>
              <li>
                <strong>Wrong pocket removal recovery:</strong> Enhanced
                recovery process when the contents of the wrong pocket are
                removed. Instructions quickly and easily guide the user through
                the recovery, significantly reducing downtime and increasing
                productivity.
              </li>
            </ul>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Available options</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="p-4">
                    <h4>
                      <strong>
                        Enhance the speed, accuracy and security of your
                        currency handling operation
                      </strong>
                    </h4>
                    <ul className="mb-4 ml-4 list-disc">
                      <li>
                        <strong>One-Touch Plus operating software:</strong>{' '}
                        Enhanced software with additional functionality
                        including coin and non-cash-item entry, supervisor and
                        operator sign-on with passwords, and six programmable
                        processing types.
                      </li>
                      <li>
                        <strong>Counterfeit detection:</strong> JetScan iFX i400
                        series currency scanners are equipped with a wide range
                        of counterfeit detection sensors, including imaging,
                        magnetic ink, fluorescence, ultraviolet and proprietary
                        detection techniques.
                      </li>
                      <li>
                        <strong>Fitness:</strong> Physical properties of notes
                        are checked: soil, holes, dog-ears, tears, missing
                        parts, and tape.
                      </li>
                      <li>
                        <strong>Mixed fitness to one pocket:</strong> Sort
                        various denominations of varying fitness to one pocket,
                        allowing users to create a single pocket for deposit or
                        return to the Federal Reserve Bank. (Included with
                        fitness software option)
                      </li>
                      <li>
                        Variable-level fitness criteria reject: Reject bills
                        based on user-defined fitness criteria, so they are
                        rejected (uncounted) and not offsorted for being unfit
                        (counted). Allows users to reject bills for specific
                        criteria for review and reprocessing. (Included with
                        fitness software option)
                      </li>
                      <li>
                        <strong>Continuous batch processing:</strong> Lets
                        operators continuously load the feeder and process
                        batches without the machine stopping. Bar-coded control
                        documents separate batches and reduce errors from
                        manually entering batch information.
                      </li>
                      <li>
                        <strong>Higher-capacity left pockets:</strong>{' '}
                        500-document left-side pockets let operators sort bills
                        for bulk storage and cassette-filling with less
                        stoppage.
                      </li>
                      <li>
                        <strong>External bar code gun:</strong> Allows bar-coded
                        label entry; USB port interface.
                      </li>
                      <li>
                        <strong>Printers:</strong> Impact or thermal printers
                        provide records of transactions.
                      </li>
                      <li>
                        <strong>Optional currencies:</strong> Canadian dollar
                        (CAD), Mexican peso (MXN), British pound sterling (GBP),
                        Euro (EUR), Bank of England and Scotland (SCO), Russian
                        ruble (RUB), and Turkish lira (TRY)
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specs</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <th align="left" colSpan={2}>
                          Feeder
                        </th>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>
                          Automatic feeder with auto-sensing document detection.
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          Transport
                        </th>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>High-volume straight-through transport path.</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          Exit Pocket Capacity
                        </th>
                      </tr>
                      <tr>
                        <td>Maximum Capacity</td>
                        <td>250 documents.</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          Physical Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>
                          Basic 3-pocket system: 29" W x 17" D x 15" H. <br />
                          <br />
                          Additional vertical pockets: 5" H each. <br />
                          <br />
                          Additional horizontal pockets: 16" W each.
                        </td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>
                          Basic 3-pocket system: 88 lbs. <br />
                          <br />
                          Each additional 2-pocket module: 18 lbs.
                        </td>
                      </tr>
                      <tr>
                        <td>Document Size</td>
                        <td>
                          Height: 2.41" to 3.75"
                          <br />
                          <br />
                          Length: 4.5" to 8.75"
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          Currency Processing Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Adjustable Processing Speed</td>
                        <td>600, 800 and 1,000 notes per minute.</td>
                      </tr>
                      <tr>
                        <td>Denominations Scanned</td>
                        <td>$1, $2, $5, $10, $20, $50 and $100</td>
                      </tr>
                      <tr>
                        <td>Accuracy</td>
                        <td>99.99%</td>
                      </tr>
                      <tr>
                        <td>Feeder Capacity</td>
                        <td>800 notes</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          Imaging Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Document/Media Type</td>
                        <td>Casino tickets</td>
                      </tr>
                      <tr>
                        <td>Image Capture Speed</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Feeder Capacity</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Output Resolution</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Image/Data Transmission Format</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Scanning Method</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Detection</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Image Quality</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Image Enhancement Technology</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>External Bar Code Scanner</td>
                        <td>Optional for label entry via USB port.</td>
                      </tr>
                      <tr>
                        <td>External Keyboard</td>
                        <td>Optional keyboard/keypad via USB port.</td>
                      </tr>
                      <tr>
                        <td>Printer</td>
                        <td>
                          Optional Citizen impact or thermal printers available.
                        </td>
                      </tr>
                      <tr>
                        <td>Communications</td>
                        <td>USB, RS232, and Ethernet</td>
                      </tr>
                      <tr>
                        <td>Suspect Note Detection</td>
                        <td>
                          Options include imaging, magnetic ink, fluorescence,
                          ultraviolet and proprietary detection techniques.
                        </td>
                      </tr>
                      <tr>
                        <td>Touch Panel Display</td>
                        <td>
                          8", full-color, touch-screen display with on-screen
                          diagnostics.
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          Operating System
                        </th>
                      </tr>
                      <tr>
                        <td>Platform</td>
                        <td>Windows CE</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/3">
          <Image fluid={data.CurrencySorter.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

CurrencySorter.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query CurrencySorterQuery {
    CurrencySorter: file(
      relativePath: { eq: "jetscan-ifx-i400-multi-pocket-currency-sorter.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default CurrencySorter;
